import React from "react";
import { Icon } from "@iconify/react";

import "./styles/social_links.scss";

const SocialLinks = () => {
    return (
        <div className="socials-container">
            {/* <a href="https://twitter.com/vinjenks" target="_blank" rel="noreferrer">
                <Icon icon="mdi:twitter" />
            </a> */}
            <a href="https://medium.com/@vinjenks" target="_blank" rel="noreferrer">
                <Icon icon="ph:article-medium-bold" />
            </a>
            <a href="https://www.linkedin.com/in/vincentjenks/" target="_blank" rel="noreferrer">
                <Icon icon="bi:linkedin" />
            </a>
            <a href="https://discordapp.com/users/967419474644987934" target="_blank" rel="noreferrer">
                <Icon icon="bi:discord" />
            </a>
        </div>
    );
};

export default SocialLinks;
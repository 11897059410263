import React from "react";

import SocialLinks from "./social_links";

import avatarImg from "./images/portrait_bw_sq.png";

import "./styles/home.scss";

const Home = () => {
    return (
        <div className="home-container">
            <div className="home-content-container">
                <img src={avatarImg} className="avatar" alt="Portrait" title="Portrait" />
                <div className="home-content-header">
                    <h1>Vincent Jenks</h1>
                    <p>Software Engineer | Consultant | Technologist</p>
                </div>
                <div className="home-content-body">
                    <p>
                        Hi, I'm Vinnie! I've been a professional software
                        engineer, developer, architect, team leader, and
                        enthusiastic technologist, for over 20 years.
                    </p>
                    {/* <p>
                        I'm as passionate about the development process as
                        ever, specializing in open source technologies and
                        the JavaScript ecosystem.
                    </p> */}
                    <p>
                        If you'd like to get in touch with me, please 
                        find me at one of the socials below.
                    </p>
                </div>
                <div className="home-content-footer">
                    <SocialLinks />
                </div>
            </div>
        </div>
    );
};

export default Home;